


import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CoinsIcon from "@material-ui/icons/AttachMoney";
import ClassIcon from "@material-ui/icons/Class";
import DeleteIcon from "@material-ui/icons/Delete";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import { css } from "emotion";
import { GamepadCircle } from "mdi-material-ui";

import Settings from "../../../services/settings";
import ContainerCard from "../../atoms/container-card";
import { Constants } from "../../molecules/advanced-settings-form";

export const DefaultCssClickable = css`
  transition: all 0.2s !important;
  cursor: var(--cursor-pointer);
  &:hover {
    filter: brightness(0.9) !important;
  }
  &:active {
    transform: scale(0.97);
    filter: brightness(0.8) !important;
  }
`;

export const DefaultButtonCss = css`
  background: #d5d5d5;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  margin: 4px;
  cursor: var(--cursor-pointer);
  &:hover {
    filter: brightness(0.9);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3) !important;
  }
`;

export default function PlayersActions(props) {

  const options = [
    {
      name: "novo jogador",
      href: props.modal ? undefined : "/players/new",
      onClick: () => {
        if (props.modal) {
          props.setOpenModalNewPlayer(true);
        }
      },
      icon: <AddIcon />,
      color: "secondary",
      multiple: false,
      minLevelAccess: 30,
      baseBgColor: "#55FCB9",
      tooltip:
        "Caso queira adicionar apenas um aluno ao game, selecione esta opção!",
      isLink: !props.modal // Se não for modal, é um link
    },
    {
      name: "importar alunos",
      href: props.modal ? undefined : "/players/import-csv",
      onClick: event => {
        if (props.modal) {
          props.setOpenModalImportPlayer(true);
        }
      },
      icon: <PostAddIcon />,
      color: "default",
      multiple: false,
      minLevelAccess: 30,
      tooltip:
        "Precisa adicionar vários alunos de uma só vez? Essa é a melhor opção! Faça o download da planilha e siga o passo a passo.",
      isLink: !props.modal // Se não for modal, é um link
    },
    props.screenType !== "home" && {
      name: "Aplicar atitudes/missões" ,
      href: false,
      icon: <ThumbsUpDownIcon />,
      color: "default",
      multiple: props.screenType === "home" ? false : true,
      minLevelAccess: 30,
      onClick: props.onClickApplyAttitudesButton,
      baseBgColor: "#55FCB9"
    },
    {
      name: "desmarcar todos",
      onClick: () => {
        props.onSelectedPlayers([]);
      },
      multiple: true,
      baseBgColor: "lightblue"
    },
    props.screenType !== "home" && {
      name: "ajustar saldo",
      href: false,
      icon: <CoinsIcon />,
      color: "default",
      multiple: props.screenType === "home" ? false : true,
      minLevelAccess: 30,
      baseBgColor: "#FCF755",
      onClick: props.onClickRemoveOrAddCoins
    },
    {
      name: "mudar de turma",
      href: false,
      icon: <ClassIcon />,
      color: "default",
      multiple: true,
      minLevelAccess: 30,
      onClick: props.changeClass
    },
    {
      name: "mudar de game",
      href: false,
      icon: <GamepadCircle />,
      color: "default",
      multiple: true,
      minLevelAccess: 80,
      onClick: props.changeGame
    },

    {
      name: "excluir",
      href: false,
      icon: <DeleteIcon color={"red" as any} />,
      color: "red",
      multiple: true,
      minLevelAccess: 80,
      onClick: props.delete,
      baseBgColor: "#FF6B6B"
    },
    Constants.isTestEnvironment() && {
      name: "Gerar QRCodes NOVOS",
      href: false,
      icon: <></>,
      color: "default",
      multiple: true,
      minLevelAccess: 80,
      onClick: props.generateQrCode
    },
    {
      name: "Baixar QRCodes QUE JÁ EXISTEM",
      href: false,
      icon: <></>,
      color: "default",
      multiple: true,
      minLevelAccess: 80,
      onClick: props.downloadQrCode
    }
  ];

  const availableOptions = options.filter(e => {
    if (props.userLevelAccess >= e.minLevelAccess) {
    }
    return false;
  });

  return (
    <ContainerCard
      style={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        borderBottom: "1px solid #e0e0e0"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        {options.map(option => {
          if (props.userLevelAccess < option.minLevelAccess) {
            return null;
          }

          return (
            props.multiple === option.multiple &&
            (option.isLink ? (
              <a
                href={option.href as string}
                key={option.name}
                style={{
                  fontWeight: "bold",
                  textDecoration: "none"
                }}
              >
                <button
                  className={DefaultButtonCss}
                  style={{
                    backgroundColor: option.baseBgColor,
                    fontWeight: "bold"
                  }}
                >
                  {option.name}
                </button>
              </a>
            ) : (
              <button
                className={DefaultButtonCss}
                key={option.name}
                onClick={option.onClick}
                style={{
                  backgroundColor: option.baseBgColor,
                  fontWeight: "bold"
                }}
              >
                {option.name}
              </button>
            ))
          );
        })}
      </div>
      {availableOptions.length < 3 ? (
        <Grid item>

          <Typography align="right" style={{}}>
            <small>
              <em>
                <strong>{props.selectedPlayers.length}</strong> alunos selecionados
              </em>
            </small>
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography align="right">
            <small>
              <em>
                <strong>{props.selectedPlayers.length}</strong> alunos
                selecionados
              </em>
            </small>
          </Typography>
        </Grid>
      )}
    </ContainerCard>
  );
}
